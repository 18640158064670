<template>
  <div v-loading="boxLoading" class="after-sale-details-container">
    <div class="after-sale-header">
      <div class="header-title">
        <span class="order-no">申请编号：{{ detailInfo.refundNo }}</span>
        <span
          v-if="detailInfo.status === 0"
          class="request-refund"
          style="background-color: #eca000"
          >申请退款</span
        >
        <span
          v-else-if="detailInfo.status === 1"
          class="request-refund"
          style="background-color: #2861e2"
          >取消退款申请</span
        >
        <span
          v-else-if="detailInfo.status === 2"
          class="request-refund"
          style="background-color: #00a199"
          >拒绝退款</span
        >
        <span
          v-else-if="detailInfo.status === 3"
          class="request-refund"
          style="background-color: #eca000"
          >同意退款</span
        >
        <span
          v-else-if="detailInfo.status === 4"
          class="request-refund"
          style="background-color: #e86247"
          >退款成功</span
        >
        <span
          v-else-if="detailInfo.status === 5"
          class="request-refund"
          style="background-color: #e86247"
          >退款中</span
        >
      </div>
      <el-descriptions>
        <el-descriptions-item label="申请时间">{{ detailInfo.createTime }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="flex after-sale-content">
      <div class="content-l">
        <div class="content-l-block">
          <div class="blcok-title">
            <span class="title-mark"></span>
            <span class="title-text">退款产品信息</span>
          </div>
          <div
            v-for="(itemS, s) in 1"
            :key="s + 's'"
            style="margin: 20px; border: 1px solid #e0e0e0"
          >
            <ul class="flex content-img-item">
              <el-image class="content-img-l" :src="detailInfo.orderProduct.coverPictureUrl" alt=""
                ><span slot="error" class="el-image-error">暂无图片</span></el-image
              >
              <li class="content-img-r">
                <div class="flex img-r-info">
                  <span class="img-r-info-title">{{ detailInfo.orderProduct.productName }}</span>
                  <span class="img-r-info-text">￥{{ detailInfo.orderProduct.salePrice }}</span>
                </div>
                <div class="flex img-r-info">
                  <span class="img-r-info-text"></span>
                  <span class="img-r-info-text">x{{ detailInfo.orderProduct.number }}</span>
                </div>
                <div class="flex img-r-info">
                  <span class="img-r-info-text"
                    >规格：{{ detailInfo.orderProduct.specification }}</span
                  >
                </div>
                <div class="flex img-r-info">
                  <span class="img-r-info-text"
                    >计费单位：{{ detailInfo.orderProduct.priceUnit }}</span
                  >
                </div>
              </li>
            </ul>
            <div
              v-if="detailInfo.orderProduct.subList && detailInfo.orderProduct.subList.length > 0"
            >
              <ul
                v-for="(n, i) in detailInfo.orderProduct.subList"
                :key="i"
                class="flex content-img-item"
              >
                <el-image class="content-img-l" :src="n.coverPictureUrl" alt=""
                  ><span slot="error" class="el-image-error">暂无图片</span></el-image
                >
                <li class="content-img-r">
                  <div class="flex img-r-info">
                    <span class="img-r-info-title">{{ n.productName }}</span>
                    <span class="img-r-info-text">￥{{ n.salePrice }}</span>
                  </div>
                  <div class="flex img-r-info">
                    <span class="img-r-info-text"></span>
                    <span class="img-r-info-text">x{{ n.number }}</span>
                  </div>
                  <div class="flex img-r-info">
                    <span class="img-r-info-text">规格：{{ n.specification }}</span>
                  </div>
                  <div class="flex img-r-info">
                    <span class="img-r-info-text">计费单位：{{ n.priceUnit }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="content-l-block">
          <div class="blcok-title">
            <span class="title-mark"></span>
            <span class="title-text">退款信息</span>
          </div>
          <el-descriptions
            class="refund-info"
            :column="1"
            border
            :label-style="{ minWidth: '120px' }"
          >
            <el-descriptions-item label="退款金额">￥{{ detailInfo.amount }}</el-descriptions-item>
            <el-descriptions-item label="退款原因">
              <div style="word-break: break-all">{{ detailInfo.reason }}</div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="content-l-block">
          <div class="blcok-title">
            <span class="title-mark"></span>
            <span class="title-text">证明照片</span>
          </div>
          <div class="prove-photo">
            <el-image
              v-for="(item, key, i) in detailInfo.imgs"
              :key="i"
              class="prove-img"
              :src="item"
              :preview-src-list="detailInfo.imgs"
              alt=""
            >
              <span slot="error" class="el-image-error">暂无图片</span>
            </el-image>
          </div>
        </div>
      </div>
      <div class="content-r">
        <div class="blcok-title">
          <span class="title-mark"></span>
          <span class="title-text">售后记录</span>
        </div>
        <TimeLine style="margin-top: 24px" :data="activities">
          <template slot="content" slot-scope="scope">
            <template v-if="scope.data.status == 0">
              <div>退款金额：￥{{ scope.data.amount }}</div>
              <div class="newline" style="margin: 6px 0">退款原因：{{ scope.data.reason }}</div>
              <div class="flex">
                证明照片：
                <el-image
                  v-for="(item, key, i) in scope.data.imgs"
                  :key="i"
                  class="prove-img"
                  :src="item"
                  :preview-src-list="scope.data.imgs"
                  alt=""
                >
                  <span slot="error" class="el-image-error">暂无图片</span>
                </el-image>
              </div>
            </template>
            <template v-else-if="scope.data.status == 3 || scope.data.status == 2">
              <div>审核人：{{ scope.data.createUserName }}</div>
              <div class="newline">审核备注：{{ scope.data.reason }}</div>
            </template>
          </template>
        </TimeLine>
      </div>
    </div>
  </div>
</template>

<script>
import TimeLine from '@/components/TimeLine'
import { getOrderRecordRefundDetail } from '@/api/order'
export default {
  components: {
    TimeLine,
  },
  data() {
    return {
      orderServiceId: undefined,
      orderId: undefined,
      boxLoading: false,
      detailInfo: {
        orderProduct: {},
      },
      activities: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.boxLoading = true

        this.orderServiceId = this.$route.query.orderServiceId
        this.orderId = this.$route.query.orderId

        const detailRes =
          (await getOrderRecordRefundDetail({ data: this.orderServiceId })).data || {}
        if (detailRes.imgs) {
          detailRes.imgs = JSON.parse(detailRes.imgs)
        }
        this.activities = detailRes.logsDto.map((item) => {
          item.time = item.createTime
          item.imgs = JSON.parse(item.imgs)
          if (item.status === 0) {
            item.statusName = '申请退款'
          } else if (item.status === 1) {
            item.statusName = '取消退款申请'
          } else if (item.status === 2) {
            item.statusName = '拒绝退款'
          } else if (item.status === 3) {
            item.statusName = '同意退款'
          } else if (item.status === 4) {
            item.statusName = '退款成功'
          } else if (item.status === 5) {
            item.statusName = '退款失败'
          } else if (item.status === 6) {
            item.statusName = '确认线下打款'
          }
          return item
        })
        this.detailInfo = detailRes

        this.boxLoading = false
      } catch (error) {
        this.boxLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.after-sale-details-container {
  .blcok-title {
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: flex;
    align-items: center;
    color: #000000;
    padding: 11px 0;
    border-bottom: 1px solid #ededed;
    .title-mark {
      width: 3px;
      height: 20px;
      background-color: #363f47;
      border-radius: 0px 3px 3px 0px;
    }
    .title-text {
      margin-left: 20px;
    }
  }
  .request-refund {
    position: relative;
    padding: 0 6px 0 12px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: 4px;
      width: 4px;
      height: 4px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
  .after-sale-header {
    padding: 20px;
    background-color: #fff;
    .header-title {
      margin-bottom: 16px;
      .order-no {
        margin-right: 16px;
      }
    }
  }

  .after-sale-content {
    .content-l {
      // flex: 7;
      width: 70%;
      margin: 16px 16px 0 0;
      background-color: #fff;
      .content-l-block {
        .content-img-item {
          padding: 20px;
          font-size: 14px;
          .content-img-l {
            width: 80px;
            height: 80px;
            margin-right: 20px;
            vertical-align: middle;
          }
          .content-img-r {
            width: 100%;
            .img-r-info {
              justify-content: space-between;
              .img-r-info-title {
                font-weight: 500;
              }
              .img-r-info-text {
                margin-bottom: 4px;
              }
            }
          }
        }
        .refund-info {
          padding: 20px;
          ::v-deep .el-descriptions-item__label {
            width: 100px;
          }
        }
        .prove-photo {
          padding: 20px;
          .prove-img {
            margin-right: 16px;
            width: 120px;
            height: 120px;
          }
        }
      }
    }
    .content-r {
      // flex: 3;
      width: 30%;
      max-width: 500px;
      margin: 16px 0 0 0;
      padding: 0 20px 20px 20px;
      background-color: #fff;
      .prove-img {
        margin-right: 16px;
        width: 120px;
        height: 120px;
      }
    }
  }
  .newline {
    word-break: break-all;
  }
}
</style>
